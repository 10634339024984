export default {

    MONTHS: [
        "January", "February", "March",
        "April", "May", "June",
        "July", "August", "September",
        "October", "November", "December"
    ],

    MONTHS_SHORT: [
        "Jan", "Feb", "Mar", "Apr",
        "May", "Jun", "Jul", "Aug",
        "Sep", "Oct", "Nov", "Dec"
    ],

    formatThousand(x) {
        return x.toLocaleString('de-DE', { maximumFractionDigits: 0 })
    },

    parseDate(date, useShortMonth = false) {

        if(this.isBlank(date) || date == "null") { return "-" }

        let year = date.slice(0, 4)
        let month = date.slice(5, 7)
        let day = date.slice(8, 10)
        let dayInt = parseInt(day, 10)
        let monthInt = parseInt(month, 10)

        let monthString = useShortMonth ? this.MONTHS_SHORT[monthInt - 1] : this.MONTHS[monthInt - 1]

        return `${dayInt} ${monthString} ${year}`

    },

    parseHourMinute(date, useAmPm = false) {

        if(date) {
            
            if(useAmPm) {

                let suffix = "AM"
                let hour = Number(date.slice(11, 13))
                let minute = date.slice(14, 16)

                if(hour > 12) {
                    hour -= 12
                    suffix = "PM"
                }

                return `${hour}:${minute} ${suffix}`

            }
            else {
                return date.slice(11, 16)
            }

        }

        return '-'

    },

    isBlank(input) {
        return input == null || input == ''
    },

    handleErrorMessage(error) {
        if (error.response.status == 503) {
            return "Holywings Chest is under maintenance.<br />Be back soon!"
        }

        return error.response.data.message
    },

    isNullOrEmpty(input) {
        return input == null || input == '' || input == undefined
    },

}
