import Vue from 'vue'
import App from './App.vue'
import router from './router'
import axios from 'axios'
import Helper from './helper'
import { BButton, BSpinner, ModalPlugin } from 'bootstrap-vue'
import '@/assets/base.css';
import '@/assets/open-button.css';

let confetti = require('canvas-confetti');

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

// === CONSTANTS
// === LOCAL
// const BASE_API_PATH = "http://localhost:4343/"
// const BASE_IMAGE_PATH = "http://localhost/hw/images"

// === LOCAL IP
// const BASE_API_PATH = "http://192.168.20.46:4343/"
// const BASE_IMAGE_PATH = "http://192.168.20.46/hw/images"

// === PRODUCTION
const BASE_API_PATH = "https://customer.holywings.id/"
const BASE_IMAGE_PATH = "https://customer.holywings.id/"
const TOKEN = localStorage.getItem("token") || ""
const APP_TOKEN = "NjhkY2EzNmU4OTI0YTIyNDIyYjBkNjcxZmZkMTRhOTQ="
// === END CONSTANTS

Vue.component('b-button', BButton)
Vue.component('b-spinner', BSpinner)
Vue.use(ModalPlugin)

Vue.config.productionTip = false

axios.defaults.baseURL = BASE_API_PATH
axios.defaults.timeout = 120000
axios.defaults.headers.post['Accept'] = 'application/json'
axios.defaults.headers.common['Authorization'] = `Bearer ${TOKEN}`
axios.defaults.headers.common['Content-Type'] = 'application/json'
axios.defaults.headers.common['x-holywings-token'] = APP_TOKEN

Vue.prototype.$axios = axios
Vue.prototype.$BASE_IMAGE_PATH = BASE_IMAGE_PATH
Vue.prototype.$helper = Helper
Vue.prototype.$confetti = confetti

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
